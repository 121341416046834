import yaml from "js-yaml";
import Ajv from "ajv";
import dedent from "dedent";
import { evaluate } from "mathjs";

import {
  RasaAssistantScorecard,
  ManualScorecard,
  AutomaticScorecard,
  Scorecard,
} from "./ScorecardSchema";
import scorecardSchema from "./scorecard-schema.json";

const ajv = new Ajv();

ajv.addKeyword({
  keyword: "rasa_fn",
  schemaType: "string",
});

ajv.addKeyword({
  keyword: "rasa_fallback",
  schemaType: "string",
});

const validateScore = ajv.compile<RasaAssistantScorecard>(scorecardSchema);

export class ScorecardError extends Error {
  info: any;
  constructor(message: string, info: any) {
    super(message);
    this.info = info;
  }
}

export function parseScore(scoreYaml: string) {
  try {
    const score = yaml.load(scoreYaml);

    if (!validateScore(score)) {
      return new ScorecardError("Invalid scorecard", validateScore.errors);
    }

    return score;
  } catch (e) {
    return new ScorecardError("Invalid yaml", e);
  }
}

function calculateScore(...predicates: (boolean | number)[]) {
  return (
    predicates
      .map((p) =>
        typeof p === "number" ? Math.max(0, Math.min(p, 1)) : p ? 1 : 0
      )
      .reduce((value, v) => value + v, 0) / predicates.length
  );
}

export function rankMetricFromSchema(
  x: string | number | boolean | null | (string | number | boolean)[],
  definition: any
) {
  if (x === null) {
    return 0;
  }

  const fn = (() => {
   if (definition.rasa_fn) return definition.rasa_fn as string;
   switch (definition.type) {
     case "boolean": return "number(x)";
     case "array": return "size(x)[1]";
     case "string": return "0";
     default: return "x";
   }
  })();

  return Math.min(1, Math.max(0, evaluate(fn, { x })));
}

function isNotNull<T>(x: T | null): x is T {
  return x !== null;
}

export function getValueAndSchemaForCategory(
  category: keyof ManualScorecard | keyof AutomaticScorecard,
  scorecard: Scorecard
) {
  return (["manual", "auto"] as const).flatMap((type) => {
    const properties = scorecard[type][category];

    if (properties === undefined) return [];

    return Object.entries(properties).map(([key, value]) => {
      const typeProperties =
        scorecardSchema.properties.scorecard.properties[type].properties;
      // @ts-ignore This is just too complicated to type. Luckily it is typesafe by definition
      const definition = typeProperties[category].properties[key];

      // In the future we might decide to exclude based on some `rasa_ignore` key, in which case
      // we would do so here
      if (definition.type === "string") return null;

      return { value, definition };
    }).filter(isNotNull);
  });
}

export function rank(
  category: keyof ManualScorecard | keyof AutomaticScorecard,
  scorecard: Scorecard
) {
  return calculateScore(
    ...getValueAndSchemaForCategory(
      category,
      scorecard
    ).map(({ value, definition }) => rankMetricFromSchema(value, definition))
  );
}

export const metrics = [
  {
    name: "CI",
    key: "CI",
    title: "Continuous Integration",
    description:
      "All your code is in version control and is tested before merging changes.",
    why:
      "Version control and CI are key to reliably shipping a product at high velocity.",
    resources: dedent`
      - Check out [Play 4 of the CDD Playbook](https://info.rasa.com/cdd-playbook-test-fix)
      - Learn about [Integrated Version Control](https://rasa.com/docs/rasa-x/installation-and-setup/deploy#integrated-version-control)
    `,
  },
  {
    name: "CD",
    key: "CD",
    title: "Continuous Deployment",
    description: "Updates to your assistant are deployed automatically.",
    why:
      "Manual deployment steps are a bottleneck to high-velocity product development. Automation brings speed.",
    resources: dedent`
      - Learn how to [set up CI/CD](https://rasa.com/docs/rasa-x/installation-and-setup/deploy#set-up-initial-cicd)
    `,
  },
  {
    name: "Training Data Health",
    key: "training_data_health",
    title: "Training Data Health",
    description: "The quality of your feedback loop for practicing CDD.",
    why:
      "The success of your AI assistant depends on having training data that represents the real world.",
    resources: dedent`
      - Check out [Play 3 of the CDD Playbook](https://info.rasa.com/cdd-playbook-review)
      - Learn how to [Annotate NLU examples](https://rasa.com/docs/rasa-x/user-guide/annotate-nlu-examples)
      - Lean about [generating NLU data](https://rasa.com/docs/rasa/generating-nlu-data)
    `,
  },
  {
    name: "Success KPIs",
    key: "success_kpis",
    title: "Success KPIs",
    description:
      "Aligning the success of your assistant with your business outcomes.",
    why:
      "You want to ensure that all the hard work you're putting in to your assistant is generating RoI.",
    resources: dedent`
      - Check out [Play 5 of the CDD Playbook](https://info.rasa.com/cdd-playbook-track)
      - Learn how to [use the API to tag conversations programatically](https://rasa.com/docs/rasa-x/user-guide/track-progress#tagging-conversations-via-the-api)
      - Read about how Rasa used tagging to [track carbon bot's success rate](https://blog.rasa.com/using-conversation-tags-to-measure-carbon-bots-success-rate/)
    `,
  },
] as const;

export type Metric = typeof metrics[number];

export type Rankings = {
  [key in Metric["key"]]: number;
};

export function rankScore(
  { scorecard }: RasaAssistantScorecard,
  max: number
): Rankings {
  return metrics.reduce(
    (acc, { key }) => ({
      ...acc,
      [key]: rank(key, scorecard) * max,
    }),
    {} as any
  );
}
