import * as React from "react";
import { Flex, Box } from "rebass";
import { Card, Heading, Text } from "@rasahq/rasa-ui";

import { Rankings } from "../scorecard";
import { round } from "../utils";

interface Props {
  rankings: Rankings;
  max: number;
}

export default function Maturity({ rankings, max }: Props) {
  const ranks = Object.values(rankings);
  const maximumScore = ranks.length * max;
  const totalScore = ranks.reduce((acc, rank) => acc + rank, 0);

  return (
    <Card p={0}>
      <Box
        p={4}
        sx={{
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "neutral_3",
        }}
      >
        <Heading variant="h2">Current Project Maturity Level</Heading>
      </Box>
      <Flex p={4} justifyContent="space-around">
        <Box>
          <Text fontSize={5} fontWeight={700}>
            {round(totalScore, 1)} / {maximumScore}
          </Text>
          <Text sx={{ textTransform: "uppercase" }}>Total score</Text>
        </Box>
      </Flex>
    </Card>
  );
}
