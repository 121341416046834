import * as React from "react";
import { Icon, Text } from "@rasahq/rasa-ui";
import { Flex, Box } from "rebass";

import Markdown from "./Markdown";

const formatter = new Intl.NumberFormat();
interface Props {
  value: boolean | string | number | (boolean | string | number)[];
  definition: any;
}

export default function Grade({ value, definition }: Props) {
  // const grade = rankMetricFromSchema(value, definition);

  let indicator = null;

  switch (typeof value) {
    case "string":
      return null;
    case "boolean":
      indicator = (
        <Icon
          icon={["fas", "check-circle"]}
          color={value ? "primary_1" : "neutral_4"}
        />
      );
      break;
    case "number":
      indicator = (
        <>
          {formatter.format(value)}
          {definition.type === "number" ? "%" : ""}
        </>
      );
      break;
    case "object":
      if (Array.isArray(value)) {
        indicator = value.length > 0 ? <>{value.join(", ")}</> : <i>none</i>;
      }
      if (value === null) {
        indicator = <i>{definition.rasa_fallback || "n/a"}</i>;
      }
      break;
  }

  return (
    <>
      <Flex bg="white" p={3} justifyContent="space-around">
        <Text variant="bodySecondary">{indicator}</Text>
      </Flex>
      <Box bg="white" p={3}>
        <Markdown>{definition.title}</Markdown>
      </Box>
    </>
  );
}
