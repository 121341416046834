import { Heading, Separator, Text } from "@rasahq/rasa-ui";
import * as React from "react";

import { Metric } from "../scorecard";
import { RasaAssistantScorecard } from "../ScorecardSchema";

import Grades from "./Grades";
import Markdown from "./Markdown";

interface Props {
  metric: Metric;
  score: RasaAssistantScorecard;
}

export default function MetricFrame({ metric, score }: Props) {
  return (
    <>
      <Heading variant="h2" mb={3}>
        {metric.title}
      </Heading>
      <Text>{metric.description}</Text>
      <Separator my={6} />
      <Heading variant="h3" mb={3}>
        Why it matters
      </Heading>
      <Text>{metric.why}</Text>
      <Separator my={6} />
      <Heading variant="h3" mb={3}>
        How it's graded
      </Heading>
      <Grades metric={metric} score={score} />
        <Separator my={6} />
      <Heading variant="h3" mb={3}>
          Resources
      </Heading>
      <Markdown>{metric.resources}</Markdown>
    </>
  );
}
