import React from "react";
import { Flex, Box } from "rebass";
import {
    Card,
    PageLayout,
    Text,
} from "@rasahq/rasa-ui";
import { useQueryParam } from "use-query-params";

import { ScorecardError, parseScore } from "../scorecard";
import { decodeUrlSafeBase64 } from "../utils";

import UploadScorecard from "./UploadScorecard";
import Scorecard from "./Scorecard";

export default function Landing() {
    const [scorecardYaml, setScorecardYaml] = React.useState("");

    const [scorecardQuery,] = useQueryParam<string>("scorecard")

    React.useEffect(() => {
        if (scorecardQuery) {
            setScorecardYaml(decodeUrlSafeBase64(scorecardQuery));
        }
    }, [scorecardQuery]);

    const [score, error] = React.useMemo(() => {
        if (scorecardYaml === "") return [null, null];
        const score = parseScore(scorecardYaml);
        if (score instanceof ScorecardError) return [null, score];
        return [score, null];
    }, [scorecardYaml]);

    return (
        <Flex sx={{ height: "100%" }}>
            <PageLayout
                title="Rasa Enterprise Readiness Scorecard"
                content={
                    <Flex sx={{ height: "100%" }}>
                        <Box alignSelf="center">
                            <Text variant="bodySecondary">Version 1.0</Text>
                        </Box>
                    </Flex>
                }
                scrollable
            >
                {!score && (
                    <Card height="100%">
                        <UploadScorecard error={error} onChange={setScorecardYaml} />
                    </Card>
                )}
                {score && <Scorecard score={score} />}
            </PageLayout>
        </Flex>
    );
}