import * as React from "react";

import { Heading, Text } from "@rasahq/rasa-ui";

import Markdown from "./Markdown";

export default function Explainer() {
  return (
    <>
      <Heading variant="h2" mb={6}>
        What is the enterprise readiness scorecard?
      </Heading>
      <Markdown>
        This scorecard helps you implement best practices for bringing an AI
        assistant to production. Please also read the accompanying [AI Assistant
        Project Plan](https://blog.rasa.com/project-management-for-conversational-ai-teams-template/)
        which includes timelines and detailed steps. This scorecard measures the
        health and production readiness of your AI assistant, and recommends next
        steps to ensure you are following best practice and maximizing your RoI
        from working with Rasa.
      </Markdown>
      <Heading variant="h3" my={5}>
        How to use the scorecard?
      </Heading>
      <Text>
        The scorecard reports the maturity of your application (the assistant
        itself) as well as the infrastructure you have surrounding it. As you
        work with your dedicated customer success engineer, you will check the
        scorecard periodically to measure your progress, and make a plan for
        getting ready for production. We recommend that you aim to get a score
        of at least 3 in each category before considering your assistant
        production-ready.
      </Text>
    </>
  );
}
