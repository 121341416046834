import * as React from "react";
import { Flex, Box } from "rebass";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
} from "recharts";

import { Rankings, metrics } from "../scorecard";
interface Props {
  max: number;
  rankings: Rankings;
}

export default function ScoreChart({ rankings, max }: Props) {
  const data = metrics.map(({ name, key }) => ({
    metric: name,
    score: rankings[key],
  }));

  return (
    <Flex justifyContent="center">
      <Box width={500} height={350}>
        <RadarChart
          cx={290}
          cy={175}
          outerRadius={150}
          width={500}
          height={350}
          data={data}
        >
          <PolarAngleAxis dataKey="metric" />
          <PolarRadiusAxis domain={[0, max]} tick={false} />
          <PolarGrid />
          <Radar
            dataKey="score"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </Box>
    </Flex>
  );
}
