import * as React from "react";
import { Box } from "rebass";
import { Text } from "@rasahq/rasa-ui";

import { Metric } from "../scorecard";
import { round } from "../utils";

interface Props {
  metric: Metric;
  ranking: number;
  active: boolean;
  onClick: () => any;
  max: number;
}

export default function MetricTab({
  metric,
  ranking,
  active,
  onClick,
  max,
}: Props) {
  const color = active ? "neutral_0" : "primary_1";
  return (
    <Box
      flex="1"
      py={4}
      bg={active ? "primary_2" : "neutral_2"}
      sx={{
        textAlign: "center",
        borderTopLeftRadius: "normal",
        borderTopRightRadius: "normal",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Text color={color} fontSize={5} fontWeight={700}>
        {round(ranking, 1)} / {max}
      </Text>
      <Text color={color}>{metric.title}</Text>
    </Box>
  );
}
