import * as React from "react";
import { Box } from "rebass";

import { Metric, getValueAndSchemaForCategory } from "../scorecard";
import { RasaAssistantScorecard } from "../ScorecardSchema";

import Grade from "./Grade";

interface Props {
  metric: Metric;
  score: RasaAssistantScorecard;
}

export default function Grades({ metric, score }: Props) {
  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "1px",
        bg: "neutral_4",
        border: "1px solid",
        borderColor: "neutral_4",
        borderRadius: "normal",
      }}
    >
      {getValueAndSchemaForCategory(metric.key, score.scorecard).map(
        ({ value, definition }) => (
          <Grade key={definition.$id} value={value} definition={definition} />
        )
      )}
    </Box>
  );
}
