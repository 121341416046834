import * as React from "react";
import { Text } from "@rasahq/rasa-ui";

import { ScorecardError } from "../scorecard";

interface Props {
  onChange: (scorecardYaml: string) => any;
  error?: ScorecardError | null;
}

export default function UploadScorecard({ error, onChange }: Props) {
  const handleScorecardUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const { result } = fileReader;
      if (result) onChange(result.toString());
    };

    fileReader.readAsText(file);
  };

  return (
    <form>
      {error && (
        <div>
          <b>{error.message}</b>
        </div>
      )}
      <Text as="label">
        Upload scorecard: <input type="file" onChange={handleScorecardUpload} />
      </Text>
    </form>
  );
}
