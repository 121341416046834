import * as React from "react"
import { Text } from "@rasahq/rasa-ui";
import ReactMarkdown from "react-markdown";

type Props = ReactMarkdown.ReactMarkdownProps;

const renderers: Props["renderers"] = {
    "paragraph": ({ children }) => <Text variant="bodySecondary">{children}</Text>,
    "inlineCode": ({ children }) => <Text variant="code" sx={{ display: "inline" }}>{children}</Text>,
    "code": ({ children }) => <Text variant="code">{children}</Text>,
    "link": ({ children, href }) => <Text as="a" href={href} variant="bodySecondary" color="primary_1" display="inline">{children}</Text>,
    "listItem": ({ children }) => <Text as="li" variant="bodySecondary" sx={{ display: 'list-item', overflow: 'initial' }}>{children}</Text>,
};

export default function Markdown(props: Props) {
    return <ReactMarkdown renderers={renderers} {...props} />;
}