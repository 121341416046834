import { Card, Separator, Text } from "@rasahq/rasa-ui";
import * as React from "react";
import { Flex, Box } from "rebass";

import { metrics, Rankings, Metric } from "../scorecard";
import { RasaAssistantScorecard } from "../ScorecardSchema";

import MetricFrame from "./MetricFrame";
import MetricTab from "./MetricTab";

export interface Props {
  max: number;
  rankings: Rankings;
  score: RasaAssistantScorecard;
}

const METRIC_ORDER = [
  "training_data_health",
  "success_kpis",
  "CI",
  "CD",
] as const;

const orderedMetrics = [...metrics].sort(
  (a, b) => METRIC_ORDER.indexOf(a.key) - METRIC_ORDER.indexOf(b.key)
) as Metric[];

export default function Metrics({ max, rankings, score }: Props) {
  const [activeMetric, setActiveMetric] = React.useState<Metric>(
    orderedMetrics[0]
  );

  return (
    <Card p={0}>
      <Box
        px={4}
        pt={4}
        sx={{ borderBottom: "1px solid", borderColor: "neutral_3" }}
      >
        <Flex>
          <Separator>
            <Text
              variant="captionSecondary"
              sx={{ textTransform: "uppercase" }}
            >
              Application Maturity
            </Text>
          </Separator>
          <Separator>
            <Text
              variant="captionSecondary"
              sx={{ textTransform: "uppercase" }}
            >
              Infrastructure Maturity
            </Text>
          </Separator>
        </Flex>
        <Box m={2} />
        <Flex>
          {METRIC_ORDER.map((key) => {
            const metric = orderedMetrics.find((m) => m.key === key);
            return (
              metric && (
                <MetricTab
                  max={max}
                  metric={metric}
                  ranking={rankings[key]}
                  active={activeMetric.key === key}
                  onClick={() => setActiveMetric(metric)}
                />
              )
            );
          }).reduce((acc, node) => (
            <>
              {acc}
              <Box m={1} />
              {node}
            </>
          ))}
        </Flex>
      </Box>
      <Box p={5}>
        {activeMetric && <MetricFrame metric={activeMetric} score={score} />}
      </Box>
    </Card>
  );
}
