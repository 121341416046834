import React from "react";
import { Global, css } from "@emotion/core";
import { fontFace } from "polished";
import { QueryParamProvider } from "use-query-params";
import {
  ThemeProvider,
  initializeIcons,
} from "@rasahq/rasa-ui";

import Landing from "./Landing";

const { PUBLIC_URL } = process.env;

initializeIcons();

const globlaStyles = css`
  * {
    font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    letter-spacing: 0.6px;
  }

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  ${fontFace({
    fontFamily: "Lato",
    fontFilePath: `${PUBLIC_URL}/fonts/lato-v14-latin/lato-v14-latin-regular`,
    fontStyle: "normal",
    fontWeight: "400",
    fileFormats: ["woff2", "woff", "ttf", "svg"],
  })}

  ${fontFace({
    fontFamily: "Lato",
    fontFilePath: `${PUBLIC_URL}/fonts/lato-v14-latin/lato-v14-latin-700`,
    fontStyle: "normal",
    fontWeight: "700",
    fileFormats: ["woff2", "woff", "ttf", "svg"],
  })}

  ${fontFace({
    fontFamily: "IBM Plex Mono",
    fontFilePath: `${PUBLIC_URL}/fonts/ibm-plex-mono-v4-latin/ibm-plex-mono-v4-latin-regular`,
    fontStyle: "normal",
    fontWeight: "400",
    fileFormats: ["woff2", "woff", "ttf", "svg"],
  })}
`;

export default function App() {
  return (
    <QueryParamProvider>
      <ThemeProvider>
        <Global styles={globlaStyles} />
        <Landing />
      </ThemeProvider>
    </QueryParamProvider>
  );
}
