import * as React from "react";
import { Flex, Box } from "rebass";
import { Card } from "@rasahq/rasa-ui";

import { rankScore } from "../scorecard";
import { RasaAssistantScorecard } from "../ScorecardSchema";

import ScoreChart from "./ScoreChart";
import Explainer from "./Explainer";
import Maturity from "./Maturity";
import Metrics from "./Metrics";

interface Props {
  score: RasaAssistantScorecard;
}

const MAX_SCORE = 4;

export default function Scorecard({ score }: Props) {
  const rankings = React.useMemo(() => rankScore(score, MAX_SCORE), [score]);

  return (
    <Flex flexDirection="column">
      <Card p={4}>
        <Flex>
          <Box flex="4 1">
            <Explainer />
          </Box>
          <Box flex="1 0 500px" sx={{ textAlign: "center" }}>
            <ScoreChart max={MAX_SCORE} rankings={rankings} />
          </Box>
        </Flex>
      </Card>
      <Box m={2} />
      <Maturity rankings={rankings} max={MAX_SCORE} />
      <Box m={2} />
      <Metrics score={score} rankings={rankings} max={MAX_SCORE} />
    </Flex>
  );
}
